.coming {
  height: 100vh;
  width: 100vw;

  display: flex;

  .right {
    flex: 1;
    background-color: blue;
    width: 100%;
    height: 100%;

    .image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border: 10px;
      border-color: #193862;
    }
  }

  .left {
    flex: 1;
    background-color: rgb(255, 255, 255);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: space-between;

    .logo {
      width: 400px;
      margin-bottom: 50px;
      margin-left: 1%;
      margin-right: 1%;
    }

    .container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-left: 1%;
      margin-right: 1%;
      color: #193862;
      text-align: center;
      .title1 {
        font-size: 30px;
        font-weight: 500;
      }

      a {
        text-decoration: none;
        font-size: 20px;
        font-weight: 600;
        color: #193862;
        border: 2px solid #193862;
        border-radius: 5px;
        padding: 10px;
      }

      .button {
        background-color: #193862;
        color: white;
        padding-top: 15px;
        padding-bottom: 15px;
        padding-left: 25px;
        padding-right: 25px;
        font-weight: 500;
        border-radius: 10px;

        cursor: pointer;
      }
    }
    .socialmedia {
      align-self: flex-start;
      justify-items: space-around;

      color: #193862;
      margin-left: 10px;

      .icons {
        display: flex;
        justify-content: center;
        align-items: center;

        .facebook {
          color: #193862;
          height: 50px;
          width: 50px;
          cursor: pointer;
        }

        .instagram {
          color: #193862;
          height: 45px;
          width: 45px;
          cursor: pointer;
        }

        .linkedin {
          color: #193862;
          height: 45px;
          width: 45px;
          cursor: pointer;
        }

        .youtube {
          color: #193862;
          height: 55px;
          width: 55px;
          cursor: pointer;
        }
      }
    }
  }
}
@media screen and (max-width: 800px) {
  .coming {
    display: flex;
    flex-direction: column;

    .logo {
      margin-top: 10px;
      margin-bottom: 50px;
      margin-left: 1%;
      margin-right: 1%;
    }

    .right {
      margin-top: 50px;
    }
  }
}
